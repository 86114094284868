import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { IconButton, Typography } from "@mui/material";
import {
  Delete,
  Info,
  DownloadForOffline,
  Check,
  DoneAll,
} from "@mui/icons-material";
import { closeModal, openModal } from "../../store/actions/modalAction";
import moment from "moment";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { APPRAISAL_STATUS, POLICY_COMPLAINT_TYPE, SNACK_BAR_VARIETNS } from "../../utils/constants";
import { DeleteForm16DetailsApi, fetchForm16, fetchForm16DetailsApi } from "../../apis/form16.api";
import { disabilityComplaintFetchApi } from "../../apis/disabilityComplaint.api"
import { fetchDocumentDataAction } from "../../store/actions/settingsAction";
import FileDownloadComponent from "../../components/FileDownloadComponent";
import ComplainListUi from "./ComplainListUi";
import { fetchposhComplaintApi } from "../../apis/poshPolicy.api";
import PoshComplaintController from "./PoshComplaintController";
import { fetchMaternityApplicationApi } from "../../apis/maternity.api";
import { fetchtransgenderComplaintApi } from "../../apis/transgender.api";
import DisabilityAndTransgenderComplaintController from "./DisabilityAndTransgenderComplaintController";
import MaternityFormController from "./MaternityFormController";
import DisabilityComplaintModalController from "./DisabilityComplaintModalController";
import TransgenderComplaintModalController from "./TransgenderComplaintModalController";
import POSHComplaintModalController from "./POSHComplaintModalController";
import MaternityComplaintModalController from "./MaternityComplaintModalController";

const ActionButton = ({ params, setParams, date, complainButton }) => {
  console.log("KIKIKI rudren", complainButton);
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const onClick = () => {
    // dispatch(

    {
      complainButton == POLICY_COMPLAINT_TYPE.POSH && dispatch(
        openModal({
          title: "Complaint Preview",
          component: (
            <POSHComplaintModalController
              // fields={fields}
              //  data={data} date={date.valueOf()}
              // date={moment(date)} emp_id={params.employee_id}
              id={params._id}
             
            />
          ),
          size:"lg",
        })
      );
    }
    {
      complainButton == POLICY_COMPLAINT_TYPE.Disability && dispatch(
        openModal({
          title: "Complaint Preview",
          component: (
            <DisabilityComplaintModalController
              // fields={fields}
              //  data={data} date={date.valueOf()}
              // date={moment(date)} emp_id={params.employee_id}
              value={1}
              id={params._id}
            />
          ),
          size:"lg",
        })
      );
    }
    {
      complainButton == POLICY_COMPLAINT_TYPE.Transgender && dispatch(
        openModal({
          title: "Complaint Preview",
          component: (
            <TransgenderComplaintModalController
              // fields={fields}
              //  data={data} date={date.valueOf()}
              // date={moment(date)} emp_id={params.employee_id}
              id={params._id}
              value={2}
            />
            
          ),
          size:"lg",
        })
      );
    }
    {
      complainButton == POLICY_COMPLAINT_TYPE.Maternity_Application && dispatch(
        openModal({
          title: "Application Preview",
          component: (
            <MaternityComplaintModalController
              // fields={fields}
              //  data={data} date={date.valueOf()}
              // date={moment(date)} emp_id={params.employee_id}
              id={params._id}
            />
          ),
          size:"lg",
        })
      );
    }
    // )
  };

  return (
    <>
      <IconButton onClick={onClick}>
        <Info color="info" />
      </IconButton>

    </>
  );
};

const ComplainListController = ({ date }) => {
  const user = useSelector((state) => state.user);
  const [complainButton, setComplainButton] = useState(POLICY_COMPLAINT_TYPE.Disability);
  const complainButtonRef = useRef(complainButton);
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state)
  console.log("KIKIKI complainButton", complainButton);

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);


  const columns = useMemo(
    () => [
      {
        id: 0,
        fieldName: "userId",
        label: "Employee Id",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => params?.userId?.employee_id
      },
      {
        id: 1,
        fieldName: "employee_name",
        label: "Employee Name",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => params?.userId?.name
      },
      {
        id: 2,
        fieldName: "email",
        label: "Email",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => params?.userId?.email
      },
      {
        id: 3,
        fieldName: "phone",
        label: "Employee phone",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => params?.userId?.phone
      },
      //   
      {
        id: 4, fieldName: 'createdAt', label: 'Date', align: "left", sort: true, minWidth: '150px',
        renderValue: (params, setParams) => moment(params?.createdAt).format("DD/MM/YYYY")
          
      },
      {
        id: 4,
        fieldName: "action",
        label: "Action",
        align: "left",
        minWidth: "150px",
        renderValue: (params, setParams) => (
          <>
            <ActionButton
              complainButton={complainButtonRef.current}
              params={params}
              setParams={setParams}
            />
          </>
        ),
      },
    ],
    [dispatch, complainButtonRef]
  );



  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["userId.employee_id"],
    sort: "createdAt",
    sortDirection: -1,
  });
  const fetchList = () => {
    setLoading(true);

    let apiCall;
    if (complainButton === POLICY_COMPLAINT_TYPE.POSH) {
      apiCall = fetchposhComplaintApi;
    } else if (complainButton === POLICY_COMPLAINT_TYPE.Disability) {
      apiCall = disabilityComplaintFetchApi;
    } else if (complainButton == POLICY_COMPLAINT_TYPE.Maternity_Application) {
      apiCall = fetchMaternityApplicationApi;
    } else if (complainButton == POLICY_COMPLAINT_TYPE.Transgender) {
      apiCall = fetchtransgenderComplaintApi;
    }

    if (apiCall) {
      dispatch(
        callApiAction(
          async () => await apiCall({ ...filters }),
          (response) => {
            setState(response);
            console.log("Response:", response);
            setLoading(false);
          },
          (err) => {
            console.error("Error:", err);
            setLoading(false);
          }
        )
      );
    } else {
      setLoading(false); // Handle case where no API call is set
    }
  };

  useEffect(() => {
    fetchList();
  }, [filters, complainButton]);

  return (
    <>
      <ComplainListUi
        // date={date}
        columns={columns}
        filters={filters}
        setFilters={setFilters}
        setState={setState}
        callBack={fetchList}
        loading={loading}
        state={state}
        complainButton={complainButton}
        setComplainButton={setComplainButton}
        complainButtonRef={complainButtonRef}
      />
    </>
  );
};
export default memo(ComplainListController);