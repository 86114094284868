import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import AsyncDropDown from '../../components/inputs/AsyncDropDown';
import CustomInput from '../../components/inputs/CustomInput';
import { fetchDepartments } from '../../apis/department';
import chroma from 'chroma-js';
import { CenteredBox } from '../../components/layouts/common/boxes';

ChartJS.register(ArcElement, Tooltip, Legend);

const EmployeeProjectAnalyticalReportUi = ({ fields, setFields, setDepartmentData, departmentData }) => {
  console.log("departmentData", departmentData);

  const [pieChartData, setPieChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Total Time (Hours)',
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        offset: [],
      },
    ],
  });

  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${String(hours).padStart(2, '0')}H ${String(remainingMinutes).padStart(2, '0')}Min`;
  };

  useEffect(() => {
    if (departmentData && departmentData.length > 0) {
      const labels = departmentData.map((item) => item.project_name);
      const totalTimes = departmentData.map((item) => item.total_time / 60);

      const numProjects = departmentData.length;
      const colorPalette = chroma.scale('Pastel2')
        .mode('lab')
        .colors(numProjects);

      setPieChartData({
        labels,
        datasets: [
          {
            label: 'Total Time (Hours)',
            data: totalTimes,
            backgroundColor: colorPalette,
            borderColor: colorPalette,
            borderWidth: 1,
            offset: new Array(numProjects).fill(0),
          },
        ],
      });
    }
  }, [departmentData]);

  const handleChartClick = (event, elements) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      setPieChartData((prevData) => {
        const newOffset = prevData.datasets[0].offset.map((offset, i) => (i === index ? 40 : 0));
        return {
          ...prevData,
          datasets: [
            {
              ...prevData.datasets[0],
              offset: newOffset,
            },
          ],
        };
      });
    }
  };

  return (
    <Box height={"100%"} width={"100%"}>
      <Box p={2} sx={{ marginTop: '10px', width: '100%' }}>
        <Box>
          <Typography fontWeight={500} variant="h4">
            Select Department:
          </Typography>
        </Box>
        <Box sx={{ marginTop: '7px' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'flex', width: '100%' }}>
              <AsyncDropDown
                defaultVal={
                  fields?.department?.name && fields?.department?._id
                    ? {
                      _id: fields?.department?._id,
                      name: fields?.department?.name,
                    }
                    : null
                }
                InputComponent={(props) => (
                  <CustomInput
                    label="Department"
                    placeholder="Select Department"
                    {...props}
                  />
                )}
                label="Department*"
                lazyFun={async (props) => {
                  return await fetchDepartments({ ...props });
                }}
                OptionComponent={({ option, ...rest }) => {
                  return (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      {...rest}
                    >
                      <Typography ml={3} variant="h5">
                        {option.name}
                      </Typography>
                    </Box>
                  );
                }}
                onChange={async (changedVal) => {
                  console.log('Selected Department ID:', changedVal._id);

                  setFields({
                    ...fields,
                    err: '',
                    department: changedVal._id,
                  });
                }}
                titleKey="name"
                valueKey="_id"
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {departmentData && departmentData.length > 0 && fields?.department !== null ? (
        <Box p={2} sx={{ marginTop: '20px', maxHeight: '100%', width: '100%' }}>
          <Typography variant="h4" fontWeight={500}>
            Department Project Analysis
          </Typography>
          <Box display={"flex"} width={"70%"} justifyContent={"space-between"} alignItems={"flex-end"} sx={{ marginTop: '20px' }}>
            <Box mr={5}>
              {departmentData.map((item, index) => (
                <Box key={index} display="flex" alignItems="center" mb={1}>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundColor: pieChartData.datasets[0].backgroundColor[index],
                      marginRight: 1,
                    }}
                  />
                  <Typography variant="body1">
                    {item.project_name} - {formatTime(item.total_time)}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box width={400} height={400}>
              <Pie
                data={pieChartData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      enabled: true,
                      callbacks: {
                        label: function (tooltipItem) {
                          const totalMinutes = tooltipItem.raw * 60;
                          const hours = Math.floor(totalMinutes / 60);
                          const minutes = totalMinutes % 60;
                          return `${tooltipItem.label}: ${String(hours).padStart(2, '0')}H ${String(minutes).padStart(2, '0')}Min`;
                        },
                      },
                    },
                  },
                  onClick: handleChartClick,
                }}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <CenteredBox display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={"30vh"}>
          <Typography variant="h4" color="grey">
            No Projects Found.
          </Typography>
        </CenteredBox>
      )}
    </Box>
  );
};

export default EmployeeProjectAnalyticalReportUi;
