import {
    Box,
    Button,
    Typography,
  } from '@mui/material'
  
  import {
    Add,
  } from '@mui/icons-material'
  import CreateProjectController from './CreateProjectController'
  import { useCallback } from 'react'
  import { useDispatch } from 'react-redux'
  import { openModal } from '../../../store/actions/modalAction'
  import { DatePicker } from '@mui/x-date-pickers'
  import CustomInput from '../../../components/inputs/CustomInput'
  import DataTable from '../../../components/tables/DataTable'
  
  const ProjectListUi = ({ setState, callBack, fields, setFields, loading, state, columns, filters, setFilters }) => {
    const dispatch = useDispatch()
    const addholiday = useCallback(() => {
      dispatch(openModal({
        title: "Add Project",
        component: <CreateProjectController />, size: 'sm'
      }))
    }, [])
    return (
      <>
      
        <Box m={3}>
          <Box mt={4}>
            <Typography
              mb={2}
              sx={{
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '40px',
                color: '#0D0D0D',
              }}
            >
              Projects
            </Typography>
            <Button
              onClick={addholiday}
              sx={{
                display: 'flex',
                height: '100%',
                zIndex: 0,
                textTransform: 'capitalize',
                boxShadow: 'none',
                border: '1px solid #393939',
                width: '100%',
                height: '56px',
                ':hover': {
                  boxShadow: 'none',
                },
              }}
            >
              <Add color="primary" /> &nbsp;Add Projects
            </Button>
          </Box>

          <Box mt={6} sx={{ minHeight: "40vh" }}>
          <DataTable columns={columns} rows={state?.result ? state?.result : []} count={state?.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
          </Box>
  
        </Box>
      </>
    )
  }
  export default ProjectListUi