import React, { useEffect, useState } from 'react'
import EmployeeProjectAnalyticalReportUi from './EmployeeProjectAnalyticalReportUi'
import { getProjectAnalysisData } from '../../apis/dailyupdate.api'
import { callApiAction } from '../../store/actions/commonAction'
import { useDispatch } from 'react-redux'
import moment from 'moment'

const EmployeeProjectAnalyticalReportController = ({ id }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [fields, setFields] = useState({
        err: "",
        department: "",
    })
  const [departmentData, setDepartmentData] = useState([]);
      const [filters, setFilters] = useState({
          pageNo: 1,
          pageSize: 10,
          date: moment(),
      });

    const fetchProjectsByDeptId = () => {
        setLoading(true)
        if(fields.department !== ""){

            dispatch(callApiAction(
                async () => await getProjectAnalysisData({ ...filters ,department: fields?.department }),
                (response) => {
                    setDepartmentData(response)
                    console.log("object response", response);
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            ))
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchProjectsByDeptId()
    }, [fields.department])


    return (
        <EmployeeProjectAnalyticalReportUi
            fields={fields}
            setFields={setFields}
            departmentData={departmentData}
            loading={loading}
            setDepartmentData={setDepartmentData}
            filters={filters}
            setFilters={setFilters}
        />
    )
}

export default EmployeeProjectAnalyticalReportController