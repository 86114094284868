import axios from "axios";
import { getFileHeaders } from "../utils/helper";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";


export const transgenderComplaintCreateApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.transgenderCreate,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))

  return callResponse
}

export const fetchtransgenderComplaintApi = async (params) => {
  return await axios({
    url: endpoints.transgenderFetch,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => ({ status: 0, response: err.response, code: err.response.status }));
}

export const TransgenderComplaintFetchByIdApi = async (params) => {
  return await axios({
    url: endpoints.transgenderFetchById,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => ({ status: 0, response: err.response, code: err.response.status }));
}
