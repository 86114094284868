import React, { useEffect, useMemo, useState } from 'react'
import { createHoliday } from '../../../apis/holiday.api'
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../store/actions/commonAction'
import { closeModal } from '../../../store/actions/modalAction'
import { fetchHolidayDataAction } from '../../../store/actions/settingsAction'
import CreateProjectUi from './CreateProjectUi'
import { createProjects, fetchProjectsById, updateProjects } from '../../../apis/project.api'
import { callSnackBar } from '../../../store/actions/snackbarAction'
import { SNACK_BAR_VARIETNS } from '../../../utils/constants'
import useValidate from '../../../store/hooks/useValidator'
import { Box, Typography } from '@mui/material'

const CreateProjectController = ({  id }) => {
console.log(" lav id fetch", id);
    const dispatch = useDispatch()
    const validate = useValidate()
    const [loading, setLoading] = useState(false)
    const [optional, setOptional] = useState(false)
    const [err, setErr] = useState('')
    const [fields, setFields] = useState({
        err: "",
        project_name: "",
        department: "",
    })

    const [originalDocument, setOriginalDocument] = useState({})


    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.project_name,
            field: 'Project Name',
        },
        {
            required: true,
            value: fields.department,
            field: 'Department',
        },

    ]), [fields])

    const validationSchemaForUpdate = useMemo(() => ([
        {
            required: true,
            value: fields.project_name,
            field: 'Project Name',
        },
        {
            required: true,
            value: fields.department,
            field: 'Department',
        },

    ]), [fields])


    const addHoliday = (e) => {
        e.preventDefault()
        const validationResponse = validate(validationSchemaForCreate);

        if (validationResponse === true) {

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createProjects({ ...fields }),
                    (response) => {
                        // callBack()
                        dispatch(closeModal())
                        //   dispatch(fetchHolidayDataAction(settings.holiday_filters))
                        setLoading(false)
                    },
                    (err) => {
                        setLoading(false)
                        setFields(...fields, err)
                    },
                ),
            )
        } else {
            setFields({...fields, err: validationResponse});
            setLoading(false);
        }
    }


    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate);
        const updatedData = { id };

        for (let field in originalDocument) {
            if (
                originalDocument[field] &&
                fields[field] &&
                fields[field] != originalDocument[field]
            ) {
                updatedData[field] = fields[field];
            }
        }

        if (validationResponse === true) {
            setLoading(true);
            dispatch(
                callApiAction(
                    async () => await updateProjects(updatedData),
                    async (response) => {
                        // await callBack(response, updatedData);
                        // dispatch(fetchRawMaterialAction(settings.rawMaterial_filters))
                        setLoading(false);
                        dispatch(
                            callSnackBar(
                                "Projects Updated Successfully",
                                SNACK_BAR_VARIETNS.suceess
                            )
                        );
                        dispatch(closeModal("update"));
                    },
                    (err) => {
                        setLoading(false);
                        setFields({ ...fields, err });
                    }
                )
            );
        } else {
            setFields({ ...fields, err: validationResponse });
        }
    };

    const fetchById = (id) => {
        setLoading(true);
        console.log("object id", id);
        dispatch(
            callApiAction(
                async () => await fetchProjectsById({ id }),
                async (response) => {
                    setFields({ ...fields, ...response });
                    setOriginalDocument(response);
                    setLoading(false);
                },
                (err) => {
                    setFields({ ...fields, err });
                    setLoading(false);
                }
            )
        );
    };

    useEffect(() => {
        if (id) fetchById(id);
    }, [id]);


    const onSubmit = async (e) => {
        e.preventDefault()
        if (id)
            updateFunction(e)
        else
            addHoliday(e)
    }
    console.log("object iddd", id);

    return (
        <CreateProjectUi
            loading={loading}
            onSubmit={onSubmit}
            fields={fields}
            setFields={setFields}
            err={err}
            setErr={setErr}
            optional={optional}
            setOptional={setOptional}
            isUpdate={updateFunction}
            id={id}
        />

    )
}

export default CreateProjectController
