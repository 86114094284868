import axios from "axios"
import { memo, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import { Autocomplete, Grid } from "@mui/material"
import CustomInput from "../../components/inputs/CustomInput"

const CountryStateCityDropDown = ({ country, state, city, onChange = () => { } }) => {

    const API_KEY = 'VVZwSDY3b3JLTHNVSlJxTWd2T2txWGVlMW9TTXpWRG56MnB4UjVyVA=='

    const [fields, setFields] = useState({
        country,
        state,
        city
    })
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const [countriesLoading, setCountiesLoading] = useState(false)
    const [statesLoading, setStateLoading] = useState(false)
    const [citiesLoading, setCitieLoading] = useState(false)

    const dispatch = useDispatch()



    const fetchCountry = async () => {

        setCountiesLoading(true)
        await axios({
            url: "https://api.countrystatecity.in/v1/countries",
            method: "GET",
            headers: {
                "X-CSCAPI-KEY": API_KEY,
            }
        })
            .then((response) => {
                setCountries(response.data)
                setCountiesLoading(false)
            })


    }


    const fetchState = async () => {
        setStateLoading(true)
        await axios({
            url: `https://api.countrystatecity.in/v1/countries/${fields.countryIso2}/states`,
            method: "GET",
            headers: {
                "X-CSCAPI-KEY": API_KEY,
            }
        })
            .then((response) => {
                setStates(response.data)
                setStateLoading(false)
            })


    }

    const fetchCity = async () => {
        setCitieLoading(true)
        await axios({
            url: `https://api.countrystatecity.in/v1/countries/${fields.countryIso2}/states/${fields.stateIso2}/cities`,
            method: "GET",
            headers: {
                "X-CSCAPI-KEY": API_KEY,
            }
        })
            .then((response) => {
                setCitieLoading(false)
                setCities(response.data)
            })


    }
    useEffect(() => {
        fetchCountry()
    }, [])
    useEffect(() => {
        if (fields.country && fields.country != null) {
            fetchState()
        } else {
            setStates([])
        }
    }, [fields.country])
    useEffect(() => {
        if (fields.state && fields.state != null) {
            fetchCity()
        } else {
            setCities([])
        }
    }, [fields.state])



    const onCountryChangeFun = (e, val) => {
        setFields({ ...fields, country: val.value, countryIso2: val.iso2, state: '', city: '' });

    };



    const onStateChangeFun = (e, val) => {
        setFields({ ...fields, state: val.value, stateIso2: val.iso2, city: null })
        onChange(fields.country, val.value, null)

    }
    const onCityChangeFun = (e, val) => {
        setFields({ ...fields, city: val.value })
        onChange(fields.country, fields.state, val.value)

    }

    console.log("fields", fields)
    return <>
        <Grid item xs={12} md={4}>

            <Autocomplete
                disableClearable
                onChange={onCountryChangeFun}
                autoComplete={false}
                autoHighlight={false}
                disabled={countriesLoading}
                loading={countriesLoading}
                id="country"
                isOptionEqualToValue={(option) => {

                    if (option.value == fields.country) {
                        return true
                    }
                    return false
                }}
                value={fields.country}

                options={countries.map((option) => ({
                    label: option.name,
                    value: option.name,
                    iso2: option.iso2
                }))}
                fullWidth

                renderInput={(params) => <CustomInput autoComplete={false}
                    autoHighlight={false} {...params} label="Country*" />}
            />
        </Grid>
        <Grid item xs={12} md={4}>

            <Autocomplete
                key={fields.country}
                onChange={onStateChangeFun}
                disableClearable
                disabled={statesLoading || !fields.country || fields.country == ''}
                loading={statesLoading}
                id="state"
                value={fields.state}
                isOptionEqualToValue={(option) => {

                    if (option.value == fields.state) {
                        return true
                    }
                    return false
                }}
                options={states.map((option) => ({
                    label: option.name,
                    value: option.name,
                    iso2: option.iso2
                }))}
                fullWidth

                renderInput={(params) => <CustomInput {...params} label="State*" />}
            />
        </Grid>
        <Grid item xs={12} md={4}>
            <Autocomplete
                key={fields.state}
                onChange={onCityChangeFun}
                disableClearable
                disabled={citiesLoading || !fields.state || fields.state == ''}
                loading={citiesLoading}
                id="city"
                value={fields.city}
                isOptionEqualToValue={(option) => {
                    if (option.value == fields.city) {
                        return true
                    }
                    return false
                }}
                options={cities.map((option) => ({
                    label: option.name,
                    value: option.name
                }))}
                fullWidth

                renderInput={(params) => <CustomInput {...params} label="City*" />}
            />

        </Grid>

    </>

}
export default memo(CountryStateCityDropDown)