import axios from 'axios'
import { getHeaders } from '../utils/helper'
import endpoints from './endpoints'

export const fetchProjects = async (params = {}) => {

    const callResponse = await axios({
        url: endpoints.fetchProject,
        method: 'get',
        headers: getHeaders(),
        params
    })
        .then((response) => response.data)
        .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))

    return callResponse
}


export const createProjects = async (data) => {
    const callResponse = await axios({
        url: endpoints.createProject,
        method: 'post',
        headers: getHeaders(),
        data,
    })
        .then((response) => response.data)
        .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))

    return callResponse
}


export const deleteProjects = async (data) => {
    const callResponse = await axios({
        url: endpoints.deleteProject,
        method: 'delete',
        headers: getHeaders(),
        data,
    })
        .then((response) => response.data)
        .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))

    return callResponse
}

export const updateProjects = async (data) => {
    const callResponse = await axios({
        url: endpoints.updateProject,
        method: 'patch',
        headers: getHeaders(),
        data,
    })
        .then((response) => response.data)
        .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))

    return callResponse
}


export const fetchProjectsById = async (params) => {
    const callResponse = await axios({
      url: endpoints.fetchProjectById,
      method: "GET",
      headers: getHeaders(),
      params,
    })
      .then((response) => response.data)
      .catch((err) => ({status:0,response:err.response,code:err.response.status}))
  
    return callResponse
  }